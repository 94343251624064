import * as React from "react" 
import Seo from "../components/seo"
import Layout from "../components/layout"
import Form from "../components/form"
import Title from "../components/title"
import Spacer from "../components/spacer"

const IndexPage = ({data}) => (
  <>
    <Seo title="Home" />
    <Layout>
      <Spacer className='m-mt40' />
      <Title title='Contact Us' />
      <Spacer className='m-hide' />
      <p className='m0 medium p40 fade-in' data-sal data-sal-delay='400'>Leave an enquiry:</p>
      <Form />
      <Spacer />
    </Layout>
  </>
)

export default IndexPage